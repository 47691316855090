<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>联系信息设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30" style="display: flex; margin-left: 50px">
        <el-button type="primary" @click="setContact"
          >添加商品联系方式</el-button
        >
      </el-row>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="Lists"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="name"
            label="联系方式名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="phone" label="电话" min-width="150px">
          </el-table-column>
          <el-table-column label="邮箱" min-width="200px" prop="email">
          </el-table-column>
          <el-table-column
            label="创建者"
            min-width="100px"
            prop="createUsername"
          >
          </el-table-column>
          <el-table-column label="备注" min-width="150px" prop="note">
          </el-table-column>
          <el-table-column label="状态" min-width="100px">
            <template slot-scope="scope">
              <el-tag :type="scope.row.state === 1 ? 'success' : ''">{{
                scope.row.state === 1 ? "已上架" : "待上架"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="250px" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="Edits(scope.row)"
                >修改</el-button
              >
              <el-button
                :type="scope.row.isUse == 1 ? 'info' : 'danger'"
                size="mini"
                @click="deletes(scope.row)"
                :disabled="scope.row.isUse == 1 ? true : false"
                >删除</el-button
              >
              <el-button
                style="margin-top: 20px"
                :type="
                  scope.row.state === 1
                    ? scope.row.isUse == 1
                      ? 'info'
                      : 'danger'
                    : 'success'
                "
                :disabled="scope.row.isUse == 1 ? true : false"
                size="mini"
                @click="DownOut(scope.row)"
                >{{ scope.row.state === 1 ? "下架" : "上架" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      :title="titleName"
      :visible.sync="createDialogVisible"
      width="35%"
      @close="createDialogClosed"
    >
      <el-form
        :model="createForm"
        :rules="createFormRules"
        ref="createFormRef"
        label-width="120px"
      >
        <el-form-item label="联系方式名称：" prop="name">
          <el-input v-model.trim="createForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model.trim="createForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱：" prop="email">
          <el-input v-model.trim="createForm.email"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="note">
          <inputEL v-model.trim="createForm.note" @input="DesText"></inputEL>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSets">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { deleteDialog } from '@/libs/confirmDialog'
import { validatorEmail, validatorInput } from '@/components/myself/speciaChaFilt.js'
import inputEL from '@/components/myself/el_input.vue'
import { queryProductRelationPage, createProductRelation, removeProductRelation, updateProductRelationState } from '@/api/cloudmarket/objectBase'

export default {
  components: { inputEL },
  data () {
    return {
      Lists: [],
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,

      titleName: '',
      wordnum: 0,
      createDialogVisible: false,
      createForm: {
        name: '',
        phone: '',
        email: '',
        note: '',
      },
      createFormRules: {
        name: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '内容不能为空', trigger: 'blur' },

        ],
        email: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          { validator: validatorEmail, trigger: 'blur' },
        ],
        note: [
          { validator: validatorInput, trigger: 'blur' },
        ],
      },

    }
  },
  mounted () { this.getList() },
  methods: {
    async getList () {
      const res = await queryProductRelationPage(this.queryinfo.pageNum, this.queryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.Lists = res.data.data.list
      this.total = res.data.data.total
    },
    setContact () {
      this.titleName = '添加商品联系方式'
      this.createDialogVisible = true
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
    },
    DesText () {
      this.wordnum = this.createForm.note.length
    },
    confirmSets () {
      this.$refs.createFormRef.validate(async valid => {
        if (valid) {
          let quiese = this.createForm
          const res = await createProductRelation(quiese)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.createDialogVisible = false
          this.getList()
        }
      })
    },
    async Edits (para) {
      this.titleName = '修改商品联系方式'
      this.createForm = para
      this.createDialogVisible = true
    },
    async deletes (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await removeProductRelation(para.id)
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('删除成功')
        this.getList()
      }
    },
    async DownOut (para) {
      const res = await updateProductRelationState(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.getList()
    },

    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },
  }
}
</script>