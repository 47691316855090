<template>
  <el-input
    type="textarea"
    :value="value"
    :maxlength="Smaxlength"
    :placeholder="Splaceholder"
    @input="$emit('input', $event)"
    :show-word-limit="SwordLimit"
    :clearable="Sclearable"
    :rows="Srows"
  ></el-input>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Object, Boolean, Number, Array]
    },
    Smaxlength: {
      type: Number,
      default: 250
    },
    Srows: {
      type: Number,
      default: 6
    },
    Splaceholder: {
      type: String,
      default: '请输入'
    },
    Sclearable: {
      type: Boolean,
      default: true
    },
    SwordLimit: {
      type: Boolean,
      default: true
    },
  }
}
</script>
<style lang="less" scoped>
</style>