import axios from '@/libs/api.request'


// 根据可选参数获取模板/组件列表
export const getObjectBaseList = (pageNum, pageSize, quiese) => {
  let uri = '/objectBase/name/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

// ID
export const getID = (id) => {
  let uri = '/objectBase/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 上下架（启用：禁用）
export const getTemplateStateID = (id) => {
  let uri = '/objectBase/templateState/' + id
  return axios.request({
    url: uri,
    method: 'post',
    data: id
  })
}
// （启用：禁用）
export const getVersionStateID = (id, cause) => {
  let uri = '/objectBase/versionState'
  return axios.request({
    url: uri,
    method: 'get',
    params: { cause: cause, id: id }
  })
}


// 商品联系方式信息
export const createProductRelation = (quiese) => {
  let uri = '/objectBase/createProductRelation'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const queryProductRelationPage = (pageNum, pageSize) => {
  let uri = '/objectBase/queryProductRelationPage/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 商品联系信息上架列表
export const queryProductRelationList = () => {
  let uri = '/objectBase/queryProductRelationList'
  return axios.request({
    url: uri,
    method: 'get',
  })
}


export const removeProductRelation = (id) => {
  let uri = '/objectBase/removeProductRelation/' + id
  return axios.request({
    url: uri,
    method: 'get',
  })
}

export const updateProductRelationState = (id) => {
  let uri = '/objectBase/updateProductRelationState/' + id
  return axios.request({
    url: uri,
    method: 'get',
  })
}

export const updateContact = (id, relationId) => {
  let uri = '/objectBase/updateContact/' + id + '/' + relationId
  return axios.request({
    url: uri,
    method: 'get',
  })
}